@import '_breakpoint.less';
@import '_helpers.less';
@import '_helpers-forex.less';
@import '~vue3-carousel/dist/carousel.css';

:root {
    /* Color */
    --vc-clr-primary: #000;
    --vc-clr-secondary: #090f207f;
    --vc-clr-white: #ffffff;
  
    /* Icon */
    --vc-icn-width: 1.2em;
  
    /* Navigation */
    --vc-nav-width: 30px;
    --vc-nav-height: 30px;
    --vc-nav-border-radius: 0;
    --vc-nav-color: var(--vc-clr-primary);
    --vc-nav-color-hover: var(--vc-clr-secondary);
    --vc-nav-background: transparent;
  
    /* Pagination */
    --vc-pgn-width: 8px;
    --vc-pgn-height: 8px;
    --vc-pgn-margin: 6px;
    --vc-pgn-border-radius: 4px;
    --vc-pgn-background-color: var(--vc-clr-secondary);
    --vc-pgn-active-color: var(--vc-clr-primary);
  }

.carousel__pagination {
    padding: 0;
}

.f-container {
    max-width: 1182px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 30px;
    padding-right: 30px;

    .md({
        padding-left: 17px;
        padding-right: 17px;
    });
}

.f-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 14px;
    line-height: 38px;
    border: solid 2px;
    border-radius: 31px;
    padding: 0 25px;

    &-lg {
        font-size: 16px;
        line-height: 44px;
        padding-top: 7px;
        padding-bottom: 7px;
    }

    &-light {
        &, &:active, &:focus, &:hover {
            border-color: @forex-color-dark;
            background-color: transparent;
            color: @forex-color-dark;
        }

        &:hover {
            background-color: fade(@forex-color-dark, 10%);
        }
    }

    &-dark {
        &, &:active, &:focus, &:hover {
            border-color: @forex-color-dark;
            background-color: @forex-color-dark;
            color: @forex-color-light;
        }

        &:hover {
            background-color: fade(@forex-color-dark, 90%);
        }
    }

    &-green {
        &, &:active, &:focus, &:hover {
            border-color: @forex-color-green;
            background-color: @forex-color-green;
            color: @forex-color-light;
        }

        &:hover {
            background-color: fade(@forex-color-green, 90%);
        }
    }

    &-orange {
        &, &:active, &:focus, &:hover {
            border-color: @forex-color-orange;
            background-color: @forex-color-orange;
            color: @forex-color-light;
        }

        &:hover {
            background-color: darken(@forex-color-orange, 5%);
        }
    }

    &-disabled {
        &, &:active, &:focus, &:hover {
            border-color: @forex-color-disabled;
            background-color: @forex-color-disabled;
            color: @forex-color-light;
        }
    }
}

.f-block {
    border-radius: 18px;

    &-light {
        background: @forex-bg-light;
    }

    &-dark {
        background: @forex-bg-dark;
    }

    &-bordered {
        border: solid 1px @forex-border-dark;
    }

    &-overflow {
        overflow: hidden;
    }

    &_type {
        &_main-option {
            position: relative;
            padding: 72px 64px 55px 64px;
            min-height: 450px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-bottom: 60px;

            &-wide {
                padding-right: 480px;
                background: url(/i/forex/f-main-option-chart-light.png) left ~"calc(50% + 50px)" no-repeat, @forex-bg-dark;
                background-size: 792px auto, auto;

                .md({
                    padding-right: 30px;
                    background: url(/i/forex/f-main-option-chart-light.png) center center no-repeat, @forex-bg-dark;
                    background-size: cover, auto;
                });
            }

            .md({
                padding: 30px;
                margin-bottom: 25px;
                min-height: 0;
            });
        }

        &_trading-account {
            position: relative;
            padding: 70px 670px 70px 75px;
            margin: 30px 0;
            background: url(/i/forex/f-main-option-chart-light.png) left ~"calc(50% + 10px)" no-repeat, @forex-bg-dark;
            background-size: 792px auto, auto;

            .lg({
                padding: 200px 20px 50px 20px;
                text-align: center;
                background-size: 100% auto, auto;
            });

            .sm({
                background-position: right ~"calc(50% + 40px)";
                background-size: 792px auto, auto;
            })
        }
    }
}

.f-account-block {
    background-color: @forex-bg-light;
    border-radius: 12px;
    padding: 25px 30px;

    .md({
        padding: 15px 20px;
    });

    &__title {
        font-size: 18px;
        line-height: 28px;
        color: @forex-color-gray;
        margin-bottom: 15px;

        &_type {
            &_bordered {
                border-bottom: solid 1px @forex-border-dark;
                padding: 0 30px 20px 30px;
                margin: 0 -30px 15px -30px;

                .md({
                    padding: 0 20px 15px 20px;
                    margin: 0 -20px 15px -20px;
                });
            }
        }
    }

    &-x {
        background-color: @forex-bg-light;
        border-radius: 12px;

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: solid 1px @forex-border-dark;
            font-size: 18px;
            line-height: 28px;
            color: @forex-color-gray;
            padding: 25px 30px;
    
            .md({
                padding: 15px 20px;
            });
    
            &-toggler {
                transition: all 0.5s;
                
                &.toggled {
                    transform: rotate(180deg);
                }
            }
        }

        &__head {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 18px;
            line-height: 28px;
            color: @forex-color-gray;
            padding: 25px 30px;
    
            .md({
                padding: 15px 20px;
            });

            &_type {
                &_clickable {
                    cursor: pointer;
                }
            }
    
            &-toggler {
                transition: all 0.5s;
                
                &.toggled {
                    transform: rotate(180deg);
                }
            }
        }

        &__body {
            padding: 25px 30px;
    
            .md({
                padding: 15px 20px;
            });

            &_type {
                &_border-top {
                    border-top: solid 1px @forex-border-dark;
                }
            }
        }
    }
}

.f-acc-btn {
    @bl: ~".f-acc-btn";
    border-radius: 6px;
    border: solid 1px @forex-border-dark;
    display: flex;
    align-items: center;
    padding: 11px 18px;
    font-size: 14px;
    line-height: 24px;
    justify-content: center;

    &, &:active, &:focus, &:hover {
        color: @forex-color-gray;
    }

    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        margin-right: 10px;


        img {
            max-width: 24px;
            max-height: 24px;
        }
    }

    &_type {
        &_md-icon {
            .md({
                padding-left: 11px;
                padding-right: 11px;

                @{bl}__icon {
                    margin-right: 0;
                }
            });
        }

        &_icon {
            padding-left: 11px;
            padding-right: 11px;

            @{bl}__icon {
                margin-right: 0;
            }
        }

        &_dark {
            background-color: @forex-color-dark;
            border-color: @forex-color-dark;

            &, &:active, &:focus, &:hover {
                color: @forex-color-light;
            }
        }
    }
}

.f-table {
    width: 100%;

    tr {
        border-bottom: solid 1px @forex-border-dark;
    }

    td {
        padding: 25px 10px 25px 10px;
    }

    td:first-child {
        padding-left: 0;
    }

    td:last-child {
        padding-right: 0;
    }

    thead {
        tr {
            border-width: 2px;
        }

        td {
            font-size: 13px;
            color: @forex-color-gray;
        }
    }

    tbody:last-child {
        tr {
            &:last-child {
                border-bottom: none;
            }
        }
    }
}

.f-input-group {
    margin-bottom: 15px;
    position: relative;
}

.f-input-loading {
    position: absolute;
    top: 10px;
    left: 15px;
    color: @forex-color-gray;
    font-size: 25px;
    
    & i {
        animation: moveUpDown 1s linear infinite;
    }
}

@keyframes moveUpDown {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
}

.f-label {
    font-size: 13px;
    color: @forex-color-gray;
    margin-bottom: 10px;

    &_type {
        &_info {
            margin-top: 25px;
        }
    }

    &-value {
        display: inline-block;
        margin-left: 10px;
        font-size: 16px;
        color: @forex-color-dark;
    }
}

.f-input {
    background-color: @forex-color-light;
    border-radius: 6px;
    border: solid 1px @forex-border-dark;
    font-size: 16px;
    padding: 15px 20px;
    width: 100%;

    &.error {
        border-color: @forex-color-red;
    }

    &[readonly] {
        background-color: @forex-color-light-gray;
    }
}

.f-input-error {
    color: @forex-color-red;
    font-size: 13px;
    a {
        text-decoration: underline;
        &, &:active, &:focus, &:hover {
            color: @forex-color-red;
        }
    }
}

.f-input-subtext {
    margin-top: 5px;
    color: @forex-color-gray;

    b {
        font-weight: 500;
        color: @forex-color-dark;
    }
}

.f-disabled {
    opacity: 0.8;
    pointer-events: none;
}

select.f-input {
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1em;
}

.f-input-changer {
    margin-top: 5px;

    &__item {
        display: inline-block;
        border-bottom: dashed 1px fade(@forex-color-dark, 80%);
        margin: 0 7px;

        &, &:active, &:focus, &:hover {
            color: fade(@forex-color-dark, 80%);
        }
    }
}

.f-switcher {
    display: flex;
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    border-radius: 6px;
    background-color: @forex-border-dark;

    &__item {
        border-radius: 6px;
        display: block;
        padding: 9px 15px;
        
        &, &:active, &:focus, &:hover {
            color: @forex-color-gray;
        }

        &.active {
            background-color: @forex-color-dark;

            &, &:active, &:focus, &:hover {
                color: @forex-color-light;
            }
        }

        .md({
            padding: 9px 10px;
        });
    }

    &_type_huge &__item {
        width: 50%;
        text-align: center;
        font-size: 15px;
        padding: 26px 15px;
        position: relative;

        .md({
            padding: 15px 10px;
        });

        img {
            opacity: 0.35;
            margin-right: 5px;
        }

        span {
            .md({
                display: block;
                margin-top: 10px;
            })
        }

        &::before {
            content: '';
            position: absolute;
            top: 8px;
            left: 12px;
            width: 20px;
            height: 20px;
            background: url(/i/forex/f-icon-acc-check.svg) center no-repeat;
            display: none;
        }

        &.active {
            img {
                filter: invert(69%) sepia(36%) saturate(634%) hue-rotate(119deg) brightness(91%) contrast(92%);
                opacity: 1;
            }

            &::before {
                display: block;
            }
        }
    }
}

.forex-page {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: stretch;
    min-height: 100vh;
    background-color: @forex-color-light-gray;
    color: @forex-color-dark;

    &_type {
        &_main-chart {
            background-image: url(/i/forex/f-bg-chart-light.png);
            background-size: 1920px auto;
            background-position: center ~"calc(100% - 1040px)";
            background-repeat: no-repeat;

            .md({
                background-position: center ~"calc(100% - 540px)";
            });

            .sm({
                background-position: center ~"calc(100% - 1240px)";
            });
        }

        &_inner-chart {
            background-image: url(/i/forex/f-bg-chart-gray.svg);
            background-position: center ~"calc(100% - 420px)";
            background-repeat: no-repeat;

            .md({
                background-position: center ~"calc(100% - 990px)";
            });
        }

        &_account {
            background: @forex-bg-dark;

            .forex-page-content {
                padding-top: 10px;
            }
        }

        &_light {
            .forex-page-header {
                background: transparent;
                
                &-block {
                    background: @forex-color-light;
                }
            }
        }

        &_dark {
            position: relative;
            overflow: hidden;

            .forex-page-header {
                background: @forex-color-dark;
                color: @forex-color-light;

                .forex-page-header-block-wrapper {
                    position: relative;
                    z-index: 3;
                }
            
                .forex-page-header-block {
                    background: rgba(255, 255, 255, 0.15);

                    &.toggled {
                        .md({
                            background: fade(@forex-color-dark, 95%);
                        });
                    }
                }

                .forex-page-header__menu > li > a,
                .forex-page-header__account-menu-item_type_terminal {
                    &, &:active, &:focus, &:hover {
                        color: @forex-bg-light;
                    }

                    border-color: #000;
                }

                .f-btn-light {
                    &, &:active, &:focus, &:hover {
                        color: @forex-bg-light;
                        border-color: rgba(255, 255, 255, 0.1);
                    }
                }

                .f-btn-dark {
                    &, &:active, &:focus, &:hover {
                        border-color: @forex-bg-light;
                    }
                }

                .forex-page-header__account-menu-item-link {
                    &, &:active, &:focus, &:hover {
                        color: @forex-bg-light;
                    }
                }

                .forex-page-header__menu-toggle-button span {
                    background-color: @forex-color-light;
                }
            }   

            .forex-page-content {
                background: @forex-color-dark;
                color: @forex-color-light;
            }
        }
    }

    &-content {
        flex-grow: 1;
        flex-basis: 0;
    }

    &-header {
        @bl: ~".forex-page-header";
        @f-header-ani-duration: 0.5s;

        background: @forex-bg-dark;
        // min-height: 660px;

        &-block-wrapper {
            position: relative;
            height: 116px;

            .md({
                height: 96px;
            });
        }

        &-block {
            position: absolute;
            background: rgba(255, 255, 255, 0.35);
            border-radius: 12px;
            height: 70px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 30px;
            top: 23px;
            right: 30px;
            left: 30px;
            transition: all @f-header-ani-duration;
            z-index: 2;

            .md({
                height: 50px;
                padding-left: 0;
                padding-right: 0;
                right: 17px;
                left: 17px;
                flex-direction: column;
            });
        }

        &__email {
            padding: 10px 20px 5px 20px;

            .md({
                padding: 30px 30px 10px 30px;
            });

            &-status {
                font-size: 12px;
                display: inline-block;
                margin-left: 10px;

                &_type {
                    &_verified { color: @forex-color-green; }
                    &_unverified { color: @forex-color-brown; }
                }
            }
        }

        &__account-extra-menu {
            flex-grow: 1;
            display: flex;
            margin-left: 25px;

            .md({
                flex-direction: column;
                margin-left: 0;
                width: 100%;
                flex-grow: 0;
            });

            &-item {
                line-height: 22px;
                display: inline-block;
                margin: 0 25px;
                font-size: 13px;
                &, &:active, &:focus, &:hover {
                    color: @forex-color-dark;
                }
                opacity: 0.6;
                font-weight: 600;
                text-transform: uppercase;
                white-space: nowrap;

                &.arrowed {
                    margin-right: 10px;
                }

                .md({
                    opacity: 1;
                    font-size: 18px;
                    margin: 0;
                    width: 100%;
                    border-top: solid 1px @forex-border-dark;
                    padding: 20px 30px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                });
            }

            &-icon {
                display: inline-block;
                width: 24px;
                height: 24px;
                line-height: 24px;
                text-align: center;
                margin-right: 5px;
            }
        }

        &__account-menu-item {
            margin-right: 15px;
            white-space: nowrap;

            &:last-child {
                margin-right: 0;
            }

            &-link {
                &, &:active, &:focus, &:hover {
                    color: @forex-color-dark;
                }
                opacity: 0.6;
                
            }

            &_type_terminal {
                margin-right: 15px;
                opacity: 1;
                display: inline-block;
                border-radius: 20px;
                padding: 8px 15px;
                font-weight: 600;
                &, &:active, &:focus, &:hover {
                    color: @forex-color-dark;
                }

                .md({
                    order:1;
                    padding: 0;
                    background-color: transparent;
                    font-size: 18px;
                    border-radius: 0;
                    &, &:active, &:focus, &:hover {
                        span {
                            color: @forex-color-green;
                            text-transform: none;
                        }
                    }
                });

                .fi {
                    font-size: 18px;

                    .md({
                        line-height: 24px;
                    });
                }
            }

            &_type_simple {
                .md({
                    border-top: solid 1px @forex-border-dark;
                    padding: 20px 30px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    text-transform: uppercase;
                    margin: 0;
                    font-size: 18px;

                    &, &:active, &:focus, &:hover {
                        color: @forex-color-dark;
                    }
                });
            }

            &_type_settings {
                margin-right: 0;
            }

            .md({
                &-link {
                    opacity: 1;
                }
            });

            &-icon {
                .md({
                    display: none;
                });
            }

            &-span {
                display: none;
                font-size: 18px;
                color: @forex-color-dark;
                font-weight: 600;

                .md({
                    display: block;
                });
            }

            &_type {
                &_notification {
                    .md({
                        display: block;
                        margin: 0;
                        position: absolute;
                        top: 32px;
                        right: 85px;
                    });
                }
            }
        }

        &__logo {
            padding: 10px 0;
            transition: all @f-header-ani-duration;
            transform: translateY(7px);

            .md({
                position: absolute;
                left: 17px;
                top: 3px;
                transform: translateY(0px);
            });

            &-image {
                .md({
                    display: none;
                });
            }

            &-image-mob {
                display: none;
                width: 24px;
                transition: all @f-header-ani-duration;
                
                .md({
                    display: inline-block;
                });
            }
        }

        &__collapsable {
            display: flex;
            align-items: center;
            transition: all @f-header-ani-duration;
            flex-grow: 1;
            justify-content: left;

            .md({
                max-height: 0;
                padding: 0;
                opacity: 0;
                display: block;
                align-items: flex-start;
                width: 100%;
                overflow: hidden;

                display: flex;
                flex-direction: column;
                align-items: stretch;
            });
        }

        &__menu {
            display: flex;
            flex-grow: 1;
            margin-left: 35px;

            .md({
                flex-direction: column;
                margin-left: 0;
            });
            
            & > li > a {
                display: inline-block;
                color: @forex-color-dark;
                padding: 10px 15px;
                margin: 0 10px 0 0;
                font-weight: 600;
                white-space: nowrap;

                &.arrowed {
                    margin-right: 0;
                }

                .md({
                    font-size: 18px;
                    margin: 0;
                    width: 100%;
                    text-transform: uppercase;
                    border-top: solid 1px @forex-border-dark;
                    padding: 20px 30px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                });
            }
        }

        &__menu-toggle-button {
            display: none;
            width: 30px;
            height: 30px;
            background: none;
            border: none;
            position: absolute;
            right: 17px;
            top: 10px;
            transition: all @f-header-ani-duration;

            .md({
                display: block;
            });

            span {
                position: absolute;
                display: block;
                height: 3px;
                background-color: @forex-color-dark;
                border-radius: 2px;
                transition: all @f-header-ani-duration;

                &:first-child {
                    width: 28px;
                    top: 8px;
                    left: 1px;
                    transform-origin: top left;
                }
                &:last-child {
                    width: 18px;
                    top: 18px;
                    left: 11px;
                    transform-origin: bottom left;
                }
            }
        }

        &__button {
            margin-right: 15px;
            white-space: nowrap;

            &:last-child {
                margin-right: 0;
            }

            .md({
                order:2;
                display: flex;
                margin: 15px 30px;

                &:last-child {
                    margin-right: 30px;
                }
            });
        }

        &__lang-switcher {
            &-wrapper {
                margin-left: 20px;
                transition: all 0.5s;

                .md({
                    margin: 0;
                    position: absolute;
                    top: 16px;
                    right: 66px;
                });
            }
        }

        .md({
            @{bl}-block.toggled {
                position: fixed;
                top: 0;
                right: 0;
                left: 0;
                background: @forex-bg-light;
                border-radius: 0;
                margin: 0;
                height: 100vh;
                z-index: 2;

                @{bl}__logo {
                    position: absolute;
                    top: 20px;
                    left: 30px;

                    @{bl}__logo-image-mob {
                        width: 36px;
                    }
                }

                @{bl}__collapsable {
                    max-height: 1000px;
                    padding: 0;
                    opacity: 1;
                    margin-top: 90px;
                    overflow-y: auto;
                }

                @{bl}__menu-toggle-button {
                    top: 27px;
                    right: 30px;

                    span {
                        &:first-child {
                            width: 32px;
                            top: 4px;
                            left: 4px;
                            transform: rotate(45deg);
                        }
                        &:last-child {
                            width: 32px;
                            top: 26px;
                            left: 4px;
                            transform: rotate(-45deg);
                        }
                    }
                }

                @{bl}__lang-switcher {
                    &-wrapper {
                        .md({
                            top: 33px;
                            right: 85px;
                        });
                    }
                }
            }
        });

        &__pulse-blob {
            position: absolute;
            top: 0;
            right: -12px;
        }
    }


    &-footer {
        border-top: solid 1px @forex-border-dark;

        &__links {
            padding: 40px 0;

            a {
                &, &:active, &:focus, &:hover {
                    color: @forex-color-dark;
                }
                display: block;
                padding: 10px 10px 10px 0;
                margin-bottom: 20px;
            }

            &-icon {
                margin-right: 15px;
            }
        }

        &__info {
            border-top: solid 1px @forex-border-dark;
            padding: 40px 0 30px 0;

            &-title {
                font-weight: 500;
                font-size: 13px;
                line-height: 18px;
                cursor: pointer;

                .la {
                    transition: all 0.5s;
                    transform: rotate(0);
                    // transform: rotate(180deg);

                    .md({
                        // transform: rotate(0);
                        transform: rotate(180deg);
                    })
                }
            }

            &-desc {
                display: block;
                padding-top: 15px;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                opacity: 0.6;

                .md({
                    display: none;
                })
            }

            &.toggled &-title .la {
                transform: rotate(180deg);

                .md({
                    transform: rotate(0);
                })
            }
        }

        &__copy {
            font-size: 13px;
            opacity: 0.6;
            padding: 10px 0 25px 0;
            text-align: center;

            &-menu {
                display: flex;

                .md({
                    flex-wrap: wrap;
                    justify-content: center;
                    margin-top: 15px;
                });

                a {
                    display: inline-block;
                    margin-left: 20px;
                    
                    &, &:active, &:focus, &:hover {
                        color: @forex-color-dark;
                    }

                    .md({
                        margin: 0 15px 0 0;
                    });
                }
            }
        }

        &_type_account {
            padding-top: 30px;
            border-top: none;
        }

        &_type_account &__copy {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: 400;

            .md({
                flex-direction: column;
            });
        }
    }

    &-article {
        padding: 40px 0;

        .md({
            padding: 30px 0;
        });

        &_type {
            &_no-padding {
                padding: 0;
            }

            &_no-overflow {
                overflow: hidden;
            }
            
            &_get-started {
                background-image: url(/i/forex/f-app-window-transformed.png);
                background-size: 1135px auto;
                background-position: center bottom;
                background-repeat: no-repeat;
                padding-bottom: 350px;

                .md({
                    background: none;
                    padding-bottom: 40px;
                });
            }

            &_main-payments {
                .md({
                    text-align: center;
                });
            }
        }

        &__title {
            font-weight: 700;
            font-size: 36px;
            line-height: 44px;
            margin-bottom: 20px;

            .md({
                font-size: 32px;
                line-height: 42px;
                margin-bottom: 15px;
            });
        }

        &__desc {
            font-weight: 400;
            font-size: 18px;
            line-height: 30px;
            opacity: 0.8;

            .md({
                font-size: 16px;
                line-height: 28px;
            });
        }

        &__button {
            margin-top: 30px;

            .f-btn + .f-btn {
                margin-left: 25px;

                .md({
                    margin-left: 0px;
                    margin-top: 20px;
                });
            }

            &-wide .f-btn {
                .md({
                    width: 100%;
                });
            }

            &-comment {
                font-size: 13px;
                line-height: 28px;
                opacity: 0.4;
                margin-top: 15px;

                .md({
                    font-size: 12px;
                    text-align: center;
                });
            }
        }

        &__link-more {
            font-size: 16px;
            line-height: 44px;
            font-weight: 600;

            &, &:active, &:focus, &:hover {
                color: @forex-color-dark;
            }
        }
    }

    &__header-block {
        @bl: ~".forex-page__header-block";
        padding: 60px 0 160px;
        text-align: center;
        background-image: url(/i/forex/f-bg-main-header-slider.svg);
		background-repeat: no-repeat;
		background-position: ~"calc(50% + 40px)" 20px;

        &-title {
            font-weight: 700;
            font-size: 42px;
            line-height: 62px;
            margin-bottom: 15px;

            .md({
                font-size: 32px;
                line-height: 42px;
                margin-bottom: 12px;
            });
        }

        &-desc {
            font-weight: 400;
            font-size: 18px;
            line-height: 30px;
            opacity: 0.8;
            position: relative;
            z-index: 1;

            .md({
                font-size: 16px;
                line-height: 28px;
            });
        }

        &_type {
            &_payment-methods {
                @{bl}-desc {
                    max-width: 512px;
                    margin: 0 auto;
                }
            }

            &_about {
                padding: 60px 0;

                @{bl}-desc {
                    max-width: 890px;
                    margin: 0 auto;
                }
            }

            &_platforms {
                padding: 0;
            }

            &_margintrading {
                padding: 115px 0 320px 0;
                background: 
                    url(/i/forex/f-app-window-transformed.png) center ~"calc(100% + 64px)" no-repeat,
                    url(/i/forex/f-bg-main-header-slider-green.svg) center ~"calc(50% + 45px)" no-repeat;
                background-size: 1135px auto, auto;
                
                .md({
                    padding: 65px 0 55px 0;
                    background-size: 80% auto, 100% auto;
                    background-position: center ~"calc(50% - 30px)", center ~"calc(50% - 20px)";
                });

                .xs({
                    background-size: 90% auto, 100% auto;
                    background-position: center ~"calc(50% + 30px)", center ~"calc(50% - 20px)";
                });
            }

            &_bonus {
                padding: 115px 0 120px 0;
                background: 
                    url(/i/forex/f-desk-bonus.png) ~"calc(50% + 325px)" center no-repeat,
                    url(/i/forex/f-bg-main-header-slider.svg) ~"calc(50% + 270px)" center no-repeat;
                background-size: 617px auto, auto;
                
                .md({
                    padding: 330px 0 70px 0;
                    background: url(/i/forex/f-desk-bonus.png) ~"calc(50% + 25px)" 15px no-repeat;
                    background-size: 425px auto;
                });
            }

            &_contest {
                position: relative;
                z-index: 2;
                overflow: hidden;
                padding: 165px 0 160px 0;
                background: url(/i/forex/f-contest-all.png) ~"calc(50% + 210px)" 70px no-repeat;
                background-size: 581px auto;
                
                .md({
                    padding: 370px 0 20px 0;
                    background: url(/i/forex/f-contest-all.png) center 30px no-repeat;
                    background-size: 310px auto;
                });
            }

            &_bonus-100 {
                position: relative;
                overflow: hidden;
                padding: 125px 0 160px 0;
                background: url(/i/forex/f-bonus-100-top.png) ~"calc(50% + 410px)" 30px no-repeat;
                background-size: 1055px auto;
                
                .md({
                    padding: 20px 0 380px 0;
                    background: url(/i/forex/f-bonus-100-top.png) ~"calc(50% + 50px)" bottom no-repeat;
                    background-size: 610px auto;
                });
            }

            &_bonus-disabled {
                padding: 115px 0 120px 0;
                background: 
                    url(/i/forex/f-desk-bonus-disabled.png) ~"calc(50% + 325px)" center no-repeat,
                    url(/i/forex/f-bg-main-header-slider.svg) ~"calc(50% + 270px)" center no-repeat;
                background-size: 617px auto, auto;
                
                .md({
                    padding: 330px 0 70px 0;
                    background: url(/i/forex/f-desk-bonus-disabled.png) ~"calc(50% + 25px)" 15px no-repeat;
                    background-size: 425px auto;
                });
            }

            &_ib-top {
                padding: 115px 0 120px 0;
                background: 
                    url(/i/forex/f-ib-top-image.png) ~"calc(50% + 325px)" center no-repeat,
                    url(/i/forex/f-bg-main-header-slider.svg) ~"calc(50% + 270px)" center no-repeat;
                background-size: 571px auto, auto;
                
                .md({
                    padding: 330px 0 70px 0;
                    background: 
                        url(/i/forex/f-ib-top-image.png) ~"calc(50% + 25px)" 15px no-repeat,
                        url(/i/forex/f-bg-main-header-slider.svg) ~"calc(50% + 10px)" 45px no-repeat;
                    background-size: 365px auto, auto;
                });
            }
        }
    }

    &__lefal-text {
        padding: 30px 0;
        max-width: 960px;
        margin: 0 auto;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        opacity: 0.8;

        .md({
            padding: 0;
        });
    }
}

.forex-adapted-list {
    padding: 40px 0 30px 0;

    .xl-mf({
        .carousel__track {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .carousel__slide {
                width: auto !important;
            }
        }

        .carousel__pagination {
            display: none;
        }
    });

    carousel {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .md({
        padding: 20px 0 50px 0;
    });

    &__item {
        @bl: ~".forex-adapted-list__item";
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 350px;
        min-height: 260px;
        margin-bottom: 30px;
        padding: 0 25px;
        transition: all 0.5s;

        .lg({
            width: 100%;
            margin-left: 10px;
            margin-right: 10px;
        });

        &-icon {
            width: 60px;
            height: 60px;
            background-position: center;
            background-repeat: no-repeat;

            &_type {
                &_1 { background-image: url(/i/forex/f-icon-other-1.svg); }
                &_2 { background-image: url(/i/forex/f-icon-other-2.svg); }
                &_3 { background-image: url(/i/forex/f-icon-other-3.svg); }
                &_4 { background-image: url(/i/forex/f-icon-other-4.svg); }
                &_5 { background-image: url(/i/forex/f-icon-other-5.svg); }
                &_6 { background-image: url(/i/forex/f-icon-other-6.svg); }
                &_7 { background-image: url(/i/forex/f-icon-other-7.svg); }
                &_8 { background-image: url(/i/forex/f-icon-other-8.svg); }
                &_9 { background-image: url(/i/forex/f-icon-other-9.svg); }
                &_10 { background-image: url(/i/forex/f-icon-other-10.svg); }
                &_11 { background-image: url(/i/forex/f-icon-other-11.svg); }
                &_12 { background-image: url(/i/forex/f-icon-other-12.svg); }
                &_13 { background-image: url(/i/forex/f-icon-other-13.svg); }
            }
        }

        &-title {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            padding-top: 10px;
            position: relative;
            margin-bottom: 12px;
        }

        &-desc {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            overflow: hidden;
            max-height: 0;
            transition: all 0.5s;
            opacity: 0;

            .lg({
                max-height: 300px;
                opacity: 1;
            });
        }

        &:hover {
            box-shadow: 0px 14px 36px rgba(31, 46, 51, 0.08);

            .lg({
                box-shadow: none;
            });

            @{bl}-desc {
                max-height: 300px;
                opacity: 1;
            }
        }
    }

    .VueCarousel {
        &-wrapper {
            overflow: visible;
        }

        &-dot-container {
            line-height: 20px;
        }

        &-dot {
            opacity: 0.4;
            background-color: @forex-color-dark !important;
            width: 6px !important;
            height: 6px !important;
            vertical-align: middle;
            padding: 7px !important;

            &--active {
                opacity: 1;
                width: 8px !important;
                height: 8px !important;
            }
        }
    }

    .VueCarousel-inner {
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .lg({
        .VueCarousel-wrapper {
            overflow: hidden;
        }

        .VueCarousel-inner {
            flex-wrap: nowrap;
            justify-content: left;
        }

        .VueCarousel-slide {
            display: flex;
            justify-content: center;
        }
    });

    .VueCarousel-dot-container {
        margin: 0 !important;
    }

    .VueCarousel-dot {
        margin: 0 !important;
    }
}

.forex-block-image {
    position: absolute;
    height: 205px;
    right: 20px;
    bottom: -30px;

    &-wide {
        width: 340px;
        height: auto;
        right: 84px;
        bottom: -18px;

        .md({
            display: none;
        });
    }

    .md({
        height: 150px;
        right: -30px;
    });
}

.forex-local-faq {
    &__item {
        padding: 0 50px;
        margin-bottom: 20px;

        .md({
            padding: 0 35px;
        });

        &-question {
            padding: 25px 0;
            font-size: 18px;
            line-height: 28px;
            font-weight: 600;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;

            i {
                margin-left: 10px;
            }

            i::before {
                content: "\F2C2";
            }

            .md({
                font-size: 16px;
                line-height: 24px;
            })
        }

        &-answer {
            display: none;
            padding: 20px 0 30px 0;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            border-top: solid 1px @forex-border-dark;
        }

        &.toggled &-answer {
            display: block;
        }

        &.toggled &-question i::before {
            content: "\F28E";
            opacity: 0.4;
        }
    }

    &__more-link {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        display: inline-block;
        margin-top: 20px;

        &, &:active, &:focus, &:hover {
            color: @forex-color-gray;
            text-decoration: underline;
        }

        .md({
            font-size: 14px;
        });
    }
}

.overflow-hidden {
    overflow: hidden;
}

.f-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80px;
    div {
        width: 30px;
        height: 30px;
        background: url(/i/forex/f-logo-icon.svg) center no-repeat;
        background-size: 100%;
        animation: spin 1.3s ease-in-out infinite;
    }

    &_type {
        &_big {
            div {
                width: 50px;
                height: 50px;
            }
        }
    }
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}



.f-copy-link {
    display: inline-block;
    width: 24px;
    height: 24px;
    background: url(/i/forex/f-icon-copy.svg) center no-repeat;
    cursor: pointer;
    vertical-align: middle;
    margin: -2px 0 0 3px;
}

.f-account {
    &__todo-block {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		padding: 20px 0 20px 0;
		max-width: 360px;
		margin: 0 auto;

		&-icon {
			background: url(/i/forex/f-icon-todo.svg) center no-repeat;
			width: 80px;
			height: 80px;

            &_type {
                &_empty { background-image: url(/i/forex/f-icon-folder-empty.svg); }
            }
		}
		
		&-desc {
			font-size: 16px;
			line-height: 24px;
			color: @forex-color-gray;
			margin: 20px 0;
			max-width: 260px;
		}
	}

	&__create-block {
		min-height: 600px;
		height: 100%;
		display: flex;
		flex-direction: column;

        .md({
            min-height: 430px;
        });

        &-switcher-wrapper {
            .md({
                margin-left: -20px;
                margin-right: -20px;
            });
        }

		&-body {
			flex-grow: 1;
		}

		&-footer {
			border-top: solid 1px @forex-border-dark;
			padding: 30px 30px 10px 30px;
			margin: 0 -30px 0 -30px;
            display: flex;
            flex-wrap: wrap;
            row-gap: 20px;
            align-items: center;

			.f-btn {
				margin: 0 10px 0 0;

                .md({
                    width: 100%;
                    margin: 0;

                    &:first-child {
                        margin-top: 0;
                    }
                });
			}

            .md({
                margin: 20px -20px 0 -20px;
                padding: 20px 20px 10px 20px;
                flex-direction: column;
            });
		}

		.f-label {
			margin-top: 15px;
		}

		&_type {
			&_disabled {
				opacity: 0.7;
				pointer-events: none;
			}
		}

		&-success {
			text-align: center;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			&-title {
				font-size: 18px;
				margin: 15px 0;
			}

			&-desc {
				font-weight: 400;
				font-size: 14px;
				line-height: 24px;
				color: @forex-color-gray;

                b {
                    color: @forex-color-dark;
                }
			}

			&-data {
				text-align: left;
				border: 1px solid @forex-color-green;
				border-radius: 6px;
				margin-top: 15px;
				width: 100%;
				padding: 15px 30px;

                .md({
                    text-align: center;
                });

				&-label {
					font-size: 13px;
					color: @forex-color-gray;
				}

				&-value {
					font-size: 16px;
				}
			}

            &-data-warn {
                padding: 15px 18px;
                font-weight: 400;
                border: 1px solid @forex-color-orange;
                border-radius: 6px;
                margin-top: 15px;
                width: 100%;
                text-align: left;

                .md({
                    text-align: center;
                });
            }
		}
	}

	&__create-block &__todo-block { flex-grow: 1; }

    &__convert-block {
        text-align: left;
        margin-bottom: 30px;
        background-color: @forex-color-light-trans;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .md({
            flex-direction: column;
            align-items:flex-start;
        });

        &-title {
            font-weight: 700;
        }

        &-desc {
            font-weight: 400;
        }

        &-button {
            margin: 0 0 0 20px;
            white-space: nowrap;

            .md({
                margin: 20px 0 0 0;
                width: 100%;
            })
        }

        &-amount {
            font-size: 20px;
            margin-bottom: 20px;
        }

        &-label-value {
            display: inline-block;
            margin-left: 5px;
            font-size: 16px;
            color: @forex-color-dark;
        }

        &-expires-in {
            font-size: 13px;
            color: @forex-color-gray;
            display: inline-block;
            margin: 0 0 0 25px !important;

            .md({
                margin: 15px 0 0 0 !important;
                width: 100%;
            });

            b {
                font-weight: 500;
                color: @forex-color-dark;
            }
        }

        &-too-low {
            color: @forex-color-brown;
            font-size: 16px;
            line-height: 24px;
        }
    }

    &__info-block {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &-icon {
            margin-bottom: 25px;
        }

        &-desc {
            color: @forex-color-gray;
            font-size: 16px;
            line-height: 24px;
            max-width: 350px;
            text-align: center;
            font-weight: 400;

            b {
                color: @forex-color-dark;
            }

            &_type_small {
                font-size: 13px;
                line-height: 18px;

                b {
                    font-weight: 400;
                }
            }
        }
    }

    &__table {
        &-item {
            &-icon {
                width: 24px;
            }
        }

        &-status-dot {
            display: inline-block;
            width: 6px;
            height: 6px;
            border-radius: 3px;
            vertical-align: middle;
            margin-right: 5px;

            &_type {
                &_success    { background-color: @forex-color-green; }
                &_processing { background-color: @forex-color-brown; }
                &_canceled   { background-color: @forex-color-red; }
                &_canceling  { background-color: @forex-color-brown; }
            }
        }

		.md({
			margin-left: -20px;
			margin-right: -20px;

			tbody {
				border-top: solid 2px @forex-border-dark;

				td:first-child {
					padding: 0;
				}
			}

			&-item {
				padding: 20px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex-wrap: wrap;

				&-wrapper.toggled {
					background-color: @forex-color-light-gray;
				}

				&-arrow {
					transition: all 0.5s;
				}

				&-wrapper.toggled &-arrow {
					transform: rotate(180deg);
				}

				&-info {
					display: none;
					position: relative;
					background-color: @forex-color-light-gray;
					width: 100%;
					padding: 20px;
					font-size: 13px;

					&:before {
						content: '';
						position: absolute;
						border-top: solid 1px @forex-border-dark;
						top: 0;
						left: 20px;
						right: 20px;
						height: 0;
					}

					&-li {
						display: flex;
						justify-content: space-between;
						align-items: flex-start;
						margin-bottom: 10px;

						&-label {
							color: @forex-color-gray;
						}
					}

					.account-list__button {
						width: 100%;
						margin: 15px 0 0 0;
						background-position: 10px center;
						text-align: left;
						display: flex;
						padding: 0 20px 0 40px;
						justify-content: flex-start;
						align-items: center;

						&, &:active, &:focus, &:hover {
							color: @forex-color-dark;
						}
					}
				}

				&-wrapper.toggled &-info {
					display: block;
				}
			}
		});

        &_type {
            &_simple {
                td {
                    padding-top: 12px;
                    padding-bottom: 12px;
                }

                .md({
                    margin-left: 0;
                    margin-right: 0;

                    tbody {
                        td:first-child {
                            padding-right: 10px;
                        }
                    }
                });
            }
        }
	}

    &__type-selector {
        @bl: ~".f-account__type-selector";
        margin: 15px 0 30px 0;
        &-item {
            display: block;
            border: solid 1px @forex-border-dark;
            padding: 10px 20px;
            margin-top: -1px;
            min-height: 90px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            z-index: 1;
            position: relative;

            &, &:active, &:focus, &:hover {
                color: @forex-color-dark;
            }

            &:first-child {
                border-radius: 6px 6px 0 0;
            }

            &:last-child {
                border-radius: 0 0 6px 6px;
            }

            &.active {
                border-color: @forex-color-green;
                z-index: 2;
            }

            &-title {
                font-size: 15px;
                line-height: 20px;
                font-weight: 600;
            }

            &-desc {
                font-size: 12px;
                color: @forex-color-gray;

                b {
                    font-weight: 500;
                    color: @forex-color-dark;
                }
            }

            &-radio {
                margin-right: 15px;
                width: 16px;
                height: 16px;
                border-radius: 8px;
                background-color: transparent;
                border: solid 3px @forex-color-light;
                box-shadow: 0 0 1px 1px fade(@forex-color-gray, 30%);
                transition: all 0.3s;
                flex-shrink: 0;
            }

            &.active &-radio {
                background-color: @forex-color-green;
                box-shadow: 0 0 1px 1px @forex-color-green;
            }
        }
    }
}

.grecaptcha-badge {
    z-index: 1;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 1px;
}

.f-toggle_ms_mode {
    display: block;
    background-color: @forex-color-green;
    border: solid 1px darken(@forex-color-green, 5%);
    border-radius: 6px;
    margin-left: 50px;
    font-weight: 600;
    padding: 7px 20px;
    text-align: center;

    &, &:active, &:focus, &:hover {
        color: @forex-color-light;
    }

    .md({
        margin: 15px 30px;
        order: 2;
        padding: 15px 20px;
    });

    .la {
        font-weight: 900;
        margin-left: 4px;
    }
}

.f-pulse-blob {
    display: inline-block;
    background: @forex-color-green;
	border-radius: 50%;
	height: 6px;
	width: 6px;

	box-shadow: 0 0 0 0 @forex-color-green;
	transform: scale(1);
	animation: pulse-blob 3s infinite;
}

@keyframes pulse-blob {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 @forex-color-green;
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

// redefine modal styles

body.modal-open {
    height: auto;
}

.modal-backdrop {
    background-color: @forex-color-gray;
}

.modal-dialog {

    .xs({
        margin: 15px;
    });
}

.modal-dialog-centered .modal-content {
    border-radius: 12px;

    .modal-header {
        padding: 20px 30px;
        align-items: center;
        border-bottom: solid 2px @forex-border-dark;
        
        .md({
            padding-left: 25px;
            padding-right: 25px;
        });

        .modal-title {
            color: @forex-color-gray;
            font-size: 18px;
        }

        .close {
            font-size: 20px;
            color: @forex-color-gray;
        }
    }

    .modal-body {
        padding: 25px 30px;

        .md({
            padding-left: 25px;
            padding-right: 25px;
        });
    }

    .modal-footer {
        padding: 30px;
        justify-content: flex-start;
        border-top: solid 1px @forex-border-dark;
        flex-wrap: wrap;

        .md({
            padding-left: 25px;
            padding-right: 25px;
        });
    }
}

// redefine dropdown

.dropdown-menu {
    border: none;
    background: @forex-color-light;
    box-shadow: 0px 16px 32px rgba(51, 54, 63, 0.24);
    border-radius: 12px; 
    overflow: hidden;
    margin-top: 10px;

    .md({
        &.show.f-header-dropdown-menu {
            display: block;
            position: relative !important;
            box-shadow: none;
            padding: 0;
            margin: 0;
            background: @forex-color-light-gray;
            border-radius: 0;
            top: auto;
            left: auto;
            right: auto;
            bottom: auto;
            float: none;
            transform: none !important;
        }
    });
}

.dropdown-item {
    font-weight: 500;
    font-size: 14px;
    line-height: 36px;
    color: fadeout(@forex-color-dark, 40%);

    &.active {
        background-color: transparent;
        color: @forex-color-green;
    }

    &:hover {
        background-color: @forex-color-light-gray;
    }

    .md({
        &:not(.dropdown-item_type_lang) {
            font-size: 18px;
            padding: 15px 30px;
        }
    });
}

.dropdown-divider {
    .md({
        display: none;
    });
}

// redefine tooltip

.tooltip {
    z-index: 1200;
}

.f-ask-phone {
    text-align: center;
    padding: 20px;

    .md({
        padding: 20px 0;
    });

    &__icon {
        margin-bottom: 25px;
    }

    &__title {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 15px;
    }

    &__desc {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 25px;
    }

    &__form {
        text-align: left;
    }
}

.vue-tel-input {
    height: 56px;
    border: solid 1px @forex-border-dark;
    border-radius: 6px;
}

.f-status {
    display: inline-block;
    padding: 0 15px;
    font-weight: 600;
    font-size: 12px;
    line-height: 26px;
    border-radius: 6px;
    white-space: nowrap;

    &_type {
        &_success {
            background-color: fade(@forex-color-green, 10%);
            color: @forex-color-green;
        }

        &_error {
            background-color: fade(@forex-color-red, 10%);
            color: @forex-color-red;
        }

        &_warn {
            background-color: fade(@forex-color-orange, 10%);
            color: @forex-color-orange;
        }
    }
}